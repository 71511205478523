/* sm - 600px  */
/* md - 1024px */

.appBar {
  height: 56px; /* spacing(7), */
  box-shadow: 'none';
  min-width: max-content;
}

/* ---------- Links ---------- */

.buyContentLink {
  margin: 0px 16px 0px 0px; /* spacing(0,2,0,0), */
  color: #ffffff; /* palette.link.contrast, */
}

.contributorHeaderLink {
  margin: 0px 8px 0px 16px; /* spacing(0,1,0,2), */
  color: #ffffff; /* palette.link.contrast, */
  font-weight: 700; /* font.weight.bold */
  text-transform: uppercase;
}

.link:hover {
  text-decoration: underline;
}

/* ------- End of Links ------- */

.headerGrid {
  display: grid;
  width: '100vw';
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  justify-content: 'space-between';
  height: 56px; /* spacing(7), */
  min-width: 280px; /* spacing(35), */
  padding: 0px 16px 0px 16px; /* spacing(0, 2, 0, 2) */
}

.siteHeaderLogoSmall {
  width: 160px;
  height: 22px;
}

.siteHeaderLogoRegular {
  width: 248px;
  height: 22px;
}

.languageSelect {
  margin-top: 0px;
  border: 0px;
  padding-right: 8px;
}

.signUpButton {
  margin-left: 8px;
  width: auto;
  padding: 0px 16px 0px 16px; /* spacing(0, 2, 0, 2) */
  color: rgba(12, 18, 28, 0.87); /* palette.text.primary, */
  background-color: #ffffff; /* palette.white.main */
}

.signUpButton:hover {
  color: #ffffff; /* palette.white.main */
  background-color: #fc3441; /* palette.secondary.main */
}

/* Mobile views or smaller  */
@media (max-width: 600px) {
  .buyContentLink {
    display: none;
  }

  .contributorHeaderLink {
    display: none;
  }

  .siteHeaderLogoSmall {
    width: 136px;
  }

  .siteHeaderLogoRegular {
    width: 208px;
  }

  .signUpButton {
    display: none;
  }
}

/* Tablet views or smaller  */
@media (max-width: 1024px) {
  .contributorHeaderLink {
    display: none;
  }

  .languageSelect {
    display: none;
  }
}
